import React from "react"
import s from 'styled-components'

import Post from '../components/post'
import Layout from "../components/layout"
import { Text } from "../data/typography"
const originText = (
  <Text>
    우리 고씨는 제주도 고·량·부(髙·良·夫) 삼성(三姓)신화(神話)와 탐라왕국 시조 고을나왕(髙乙那王)의 역사가 있는 자랑스러운 왕손(王孫)이다.<br />

    고씨 왕세기(王世紀)는 당요갑자(唐堯甲子)에 탐라국이 건국되어 제45대 고자견왕(髙自堅王)까지 왕치시대를 통치하고, 고려(태조 21年)에 병합됨에 제46대 왕자 고말로 공(髙末老 公)이초대 성주(星主)로 작위(爵位)를 받고 탐라민정을 조선태종 (朝鮮太宗 2年)때 제17대 성주 고봉례 공(髙鳳禮 公)까지 다스렸다.<br />

    이 처럼 고씨는 상고시대부터 유구한 전통의 기반위에 고을나왕 제46세(世) 고말로 공을 중시조(中始祖)로모시고 아홉 계파의 관향(貫鄕)으로 분파되고 있는데 각 파조(派祖)님의 역사적 인물을 조감해 볼때 최초에 고려국 재상 반열에 출사한 중시조 제2세 장자 고 유는 우습유(右拾遺), 차자 고 강은 좌복야(左僕射), 삼자 고 소는 도총독(都總督)을 하였으며 제3세손 고조기는 중서시랑 평장사(中書侍郞平章事), 제4세손 고공익은 검교장소감(檢校將少監), 고영신은 이부상서(吏部尙書), 제10세손 고인단은운휘 상장군(雲麾 上將軍), 고중연은 호종공신(扈從功臣), 제11세손 고 경은 국자감(國子監), 고인비는 병부시랑(兵部侍郞), 제13세손 고 택은 판춘추관사(判春秋館事), 고신걸은 호조전서(戶曹典書), 제15세손 고득종은 세종조 한성판윤(漢城判尹)을 역임하였다.<br />

    그리고 선조(宣祖 25年)때 임진란에 순절한 의병장(의병장) 충열공 고경명 삼부자공은 일문삼강(一門三綱)충효의 귀감이고,또한 제주 삼성사(三姓祠)을 비롯한각 파조신위를 봉안한 사묘(祠廟)와 서원(書院)이 경향 각지에 36처소가 있으며,<br />

    특히 중시조를 비롯 13위(位) 조상님과 배위(配位)의 제향(祭享)을 군산 숭모사(崇慕祀) 에서,그리고 제주 재궁지(齋宮址) 탐라원성지(耽羅苑聖地)에 왕위전(王位殿)과 성주전(星主殿)을 창건하여 역대 선왕위를 봉안 매년 종묘 대제향(宗廟大祭享)을 봉행하고 있다.<br />
</Text>
)
const OriginPage = () => (
  <Layout pageTitle={'역 사'} pageSubtitle={'유 래'}>
    <Post imageUrl={'/유래.jpg'} text={originText} />
  </Layout>
)

export default OriginPage
